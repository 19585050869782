import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";

const config: FirebaseOptions = {
  apiKey: "AIzaSyACygMncj6_YNw8XoZ3_Fl4yJgLi9GdRcU",
  authDomain: "swiftlanding-page.firebaseapp.com",
  databaseURL: "https://swiftlanding-page.firebaseio.com",
  projectId: "swiftlanding-page",
  storageBucket: "swiftlanding-page.appspot.com",
  messagingSenderId: "131941851884",
  appId: "1:131941851884:web:1804ad17f076186bdb3bc9",
  measurementId: "G-56HJJX0J01",
};

const app = initializeApp(config);
const auth = getAuth(app);
const getToken = () => signInAnonymously(auth);

export { app, auth, getToken };
