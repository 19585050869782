import smoothscroll from "smoothscroll-polyfill";

import Main from "./main";

import "./styles/index.css";
import "./styles/buttons.css";
import "./styles/contactForm.css";

if (typeof window !== "undefined") {
  smoothscroll.polyfill();
}
export default Main;
