import Debug from "debug";
import { version } from "../version";

const globalContext = "swift";

// global logger object
export const globalLogger = Debug(globalContext);

export class Logger {
  private debug: Debug.IDebugger;
  private debugError: Debug.IDebugger;

  private loggingContext: string;

  constructor(loggingContext: string) {
    this.loggingContext = loggingContext;
    this.debug = globalLogger.extend(loggingContext);
    this.debugError = globalLogger.extend(loggingContext);

    this.debug.log = console.log.bind(console);
    this.debugError.log = console.error.bind(console);
  }

  public info = (arg: any, ...args: any[]) => this.debug(arg, ...args);

  public error = (arg: any, ...args: any[]) => this.debugError(arg, ...args);

  public getLoggingContext = () => this.loggingContext;
}

if (typeof window !== "undefined") {
  // global enable helpers from console
  (window as any).swiftLoggerEnable = (loggingContextMatchString = "*") =>
    Debug.enable(`${globalContext}:${loggingContextMatchString}`);
  (window as any).swiftLoggerDisable = () => Debug.disable();

  // display app version
  (window as any).swiftVersion = () => version;
}
