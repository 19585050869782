import LogRocket from "logrocket";
import { h, Component } from "preact";
import { Route, Router } from "preact-router";

import { getToken } from "src/utils/firebase";
import { Logger } from "src/utils/logger";

import AddressVerificationFullPage from "./routes/AddressDemoFullPage";
import AddressVerificationTool from "./routes/AddressVerificationTool";
import AddressVerificationToolV2 from "./routes/AddressVerificationToolV2";

const logger = new Logger("Main");

class Main extends Component {
  async componentDidMount(): Promise<void> {
    if (window.location.hostname !== "localhost") {
      try {
        const authToken = await getToken();
        LogRocket.init("mvzbvw/swiftpublicdemos");
        LogRocket.identify(authToken.user.uid);
      } catch (e) {
        logger.info("Unable to initialize logrocket");
      }
    }
  }

  render(): h.JSX.Element {
    return (
      <div id="preact_root">
        <div id="fullPageLoader">
          <div class="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
        {/* <Link href="/add_demo_full_page">Go To main page</Link> */}
        <Router>
          <Route
            path="/add_demo_full_page"
            component={AddressVerificationFullPage}
          />
          <Route
            path="/address_verification_tool"
            component={AddressVerificationTool}
          />
          <Route
            path="/address-verification"
            component={AddressVerificationToolV2}
          />
        </Router>
      </div>
    );
  }
}

export default Main;
